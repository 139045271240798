import React from 'react';
import { ImgProfile } from 'assets';
import { Line, TextDescBase, TextTitleBase } from 'components/atoms';
// import { Line, TextDescBase, TextTitleBase } from "../../atoms";

const CardCustomerSaying = () => {
  return (
    <div className="bg-gradient-to-tr from-brand-dark to-brand-light p-5 md:p-10 rounded-3xl max-w-3xl mx-auto">
      <div className="flex flex-col items-center space-y-4 md:space-y-10">
        <div className="flex flex-col items-center space-y-2 md:space-y-4">
          <TextTitleBase
            className="text-center text-white text-2xl md:text-3xl font-bold"
            title="What our customer are saying"
          />
          <Line className="border-white" />
        </div>
        <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-10">
          <div className="flex items-center space-x-5">
            <div className="p-1 bg-white rounded-full">
              <img
                className="w-16 md:w-20 h-16 md:h-20 rounded-full"
                src={ImgProfile}
                alt="img-profile"
              />
            </div>
            <div className="flex flex-col">
              <TextTitleBase
                className="text-base font-bold text-white"
                title="Edward Newgate"
              />
              <TextDescBase
                className="text-sm text-white font-light"
                desc="Founder Circle"
              />
            </div>
          </div>
          <div className="max-w-xs md:max-w-sm">
            <TextDescBase
              className="text-sm text-white font-light"
              desc="“Our dedicated patient engagement app and web portal allow
                                        you to access information instantaneously (no tedeous
                                        form, long calls, or administrative hassle) and securely”"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardCustomerSaying;
