import React from 'react';
import { IllustrationHero } from 'assets';
import { ButtonFill, TextDesc, TextTitle } from 'components/atoms';

const HomeHero = () => {
  return (
    <div className="h-screen space-y-16 md:space-y-0 md:space-x-40 flex flex-col md:flex-row justify-center items-center">
      <div className="w-full md:w-1/2">
        <div className="flex flex-col space-y-4 md:space-y-6 w-full items-center md:items-start">
          <TextTitle
            className="text-2xl md:text-5xl text-center md:text-left"
            title="Virtual healthcare "
            h1
          >
            <br className="hidden md:block" />
            for you
          </TextTitle>
          <TextDesc
            className="text-sm md:text-base text-center md:text-left"
            desc="Trafalgar provides progressive, and affordable healthcare, accessible on mobile and online for everyone"
            p
          />
          <ButtonFill name="Consult today" />
        </div>
      </div>
      <div className="w-56 md:w-1/2">
        <img
          className="w-full"
          src={IllustrationHero}
          alt="illustration-hero"
        />
      </div>
    </div>
  );
};

export default HomeHero;
