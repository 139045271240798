import React from 'react';

const About = () => {
  return (
    <div className="h-screen">
      <div className="flex justify-center items-center h-full">
        <h1 className="text-5xl font-semibold text-brand">About us</h1>
      </div>
    </div>
  );
};

export default About;
