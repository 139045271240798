import React from 'react';
import { CardCustomerSaying } from 'components/molecules';

const CustomerSaying = () => {
  return (
    <div className="flex justify-center py-10 lg:py-20">
      <CardCustomerSaying />
    </div>
  );
};

export default CustomerSaying;
