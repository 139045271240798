import React from 'react';
import { IllustrationMobileApp } from 'assets';
import { ButtonOutline, Line, TextDesc, TextTitle } from 'components/atoms';

const HomeMobileApp = () => {
  return (
    <div className="py-10 lg:py-20 space-y-16 md:space-y-0 md:space-x-40 flex flex-col md:flex-row justify-center items-center">
      <div className="w-full md:w-1/2">
        <div className="flex flex-col space-y-4 md:space-y-10 w-full items-center md:items-start">
          <TextTitle
            className="text-2xl md:text-4xl text-center md:text-left"
            title="Download our mobile apps"
          />
          <Line className="border-black" />
          <TextDesc
            className="text-sm md:text-base text-center md:text-left"
            desc="Our dedicated patient engagement app and web portal allow you to access information instantaneously (no tedeous form, long calls, or administrative hassle) and securely"
          />
          <ButtonOutline name="Download" />
        </div>
      </div>
      <div className="w-56 md:w-1/2">
        <img
          className="w-full"
          src={IllustrationMobileApp}
          alt="illustration-hero"
        />
      </div>
    </div>
  );
};
export default HomeMobileApp;
