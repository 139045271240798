import React from 'react';
import {
  IconCounsultation,
  IconDetailInfo,
  IconEmergencyCare,
  IconPharmacy,
  IconSearchDoctor,
  IconTracking,
} from 'assets';
import { ButtonOutline, Line, TextDesc, TextTitle } from 'components/atoms';
import { CardOurService } from 'components/molecules';

const HomeOurService = () => {
  return (
    <div className="flex flex-col space-y-20 items-center justify-center w-full py-10 md:py-20">
      <div className="flex flex-col space-y-4 md:space-y-8 w-full items-center max-w-4xl">
        <TextTitle
          className="text-2xl md:text-4xl text-center"
          title="Our services"
          h1
        />
        <Line className="border-black" />
        <TextDesc
          className="text-sm md:text-base text-center"
          desc="We provide to you the best choiches for you. Adjust it to your
health needs and make sure your undergo treatment with our highly qualified doctors you can consult with us which type of service is suitable for your health"
          p
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-10">
        <CardOurService src={IconSearchDoctor} alt="icon-search-doctor">
          <TextTitle className="text-base md:text-lg" title="Search Doctor" />
          <TextDesc
            className="text-sm md:text-base"
            desc="Choose your doctor from thousands of specialist, general,
                                and trusted hospitals"
          />
        </CardOurService>
        <CardOurService src={IconPharmacy} alt="icon-pharmacy">
          <TextTitle className="text-base md:text-lg" title="Online Pharmacy" />
          <TextDesc
            className="text-sm md:text-base"
            desc="Buy your medicines with our mobile application with a simple
                                delivery system"
          />
        </CardOurService>
        <CardOurService src={IconCounsultation} alt="icon-consultation">
          <TextTitle className="text-base md:text-lg" title="Consultation" />
          <TextDesc
            className="text-sm md:text-base"
            desc="Free consultation with our trusted doctors and get the best
                                recomendations"
          />
        </CardOurService>
        <CardOurService src={IconDetailInfo} alt="icon-detail-info">
          <TextTitle className="text-base md:text-lg" title="Details Info" />
          <TextDesc
            className="text-sm md:text-base"
            desc="Free consultation with our trusted doctors and get the best
                                recomendations"
          />
        </CardOurService>
        <CardOurService src={IconEmergencyCare} alt="icon-emergency-care">
          <TextTitle className="text-base md:text-lg" title="Emergency Care" />
          <TextDesc
            className="text-sm md:text-base"
            desc="You can get 24/7 urgent care for yourself or your children
                                and your lovely family"
          />
        </CardOurService>
        <CardOurService src={IconTracking} alt="icon-tracking">
          <TextTitle className="text-base md:text-lg" title="Tracking" />
          <TextDesc
            className="text-sm md:text-base"
            desc="Track and save your medical history and health data"
          />
        </CardOurService>
      </div>
      <div className="flex justify-center w-full">
        <ButtonOutline name="Learn More" />
      </div>
    </div>
  );
};

export default HomeOurService;
