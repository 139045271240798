import React from 'react';
import { ImgArticle, ImgArticle2, ImgArticle3 } from 'assets';
import { ButtonOutline, Line, TextTitle } from 'components/atoms';
import { CardArticle } from 'components/molecules';

const Article = () => {
  return (
    <div className="flex flex-col space-y-20 items-center justify-center w-full py-10 md:py-20">
      <div className="flex flex-col space-y-4 md:space-y-8 w-full items-center max-w-4xl">
        <TextTitle
          className="text-2xl md:text-4xl text-center md:text-left"
          title="Check out our latest article"
          h1
        />
        <Line className="border-black" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-10">
        {/* Card Services */}
        <CardArticle
          to="https://google.com"
          src={ImgArticle}
          title="Disease detection, check up in the laboratory"
          desc="In this case, the role of the health laboratory is very important to do a disease detection..."
        />
        <CardArticle
          to="#"
          src={ImgArticle2}
          title="Herbal medicines that are safe for consumption"
          desc="Herbal medicine is very widely used at this time because of its very good for your health..."
        />
        <CardArticle
          to="#"
          src={ImgArticle3}
          title="Natural care for healthy facial skin"
          desc="A healthy lifestyle should start from now and also for your skin health. There are some..."
        />
      </div>
      <div className="flex justify-center w-full">
        <ButtonOutline name="View All" />
      </div>
    </div>
  );
};

export default Article;
