import React from 'react';
import { IllustrationHealthcare } from 'assets';
import { ButtonOutline, Line, TextDesc, TextTitle } from 'components/atoms';

const HomeHealthCare = () => {
  return (
    <div className="py-10 lg:py-20 space-y-16 space-y-reverse md:space-y-0 md:space-x-40 flex flex-col-reverse md:flex-row justify-center items-center">
      <div className="w-56 md:w-1/2">
        <img
          className="w-full"
          src={IllustrationHealthcare}
          alt="illustration-hero"
        />
      </div>
      <div className="w-full md:w-1/2">
        <div className="flex flex-col space-y-4 md:space-y-10 w-full items-center md:items-end">
          <TextTitle
            className="text-2xl md:text-4xl text-center md:text-right"
            title="Leading healthcare providers"
            h1
          />
          <Line className="border-black" />
          <TextDesc
            className="text-sm md:text-base text-center md:text-right"
            desc="Trafalgar provides progressive, and affordable healthcare,
                                    accessible on mobile and online for everyone. To us, it’s
                                    not just work. We take pride in the solutions we deliver"
            p
          />
          <ButtonOutline name="Learn More" />
        </div>
      </div>
    </div>
  );
};
export default HomeHealthCare;
