import PropTypes from 'prop-types';
import React from 'react';

const ButtonBase = (props) => {
  return (
    <button
      type="button"
      className={` ${props.className} px-4 py-2 rounded-3xl`}
    >
      {props.children}
    </button>
  );
};

export const ButtonOutline = (props) => {
  return (
    <ButtonBase>
      <span className="text-brand text-base">{props.name}</span>
    </ButtonBase>
  );
};
export const ButtonFill = (props) => {
  return (
    <ButtonBase className="bg-brand w-36 md:w-40">
      <span className="text-white text-base">{props.name}</span>
    </ButtonBase>
  );
};

ButtonBase.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

ButtonFill.propTypes = {
  name: PropTypes.string.isRequired,
};

ButtonOutline.propTypes = {
  name: PropTypes.string.isRequired,
};
