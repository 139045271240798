import React from 'react';
import { IconLogo } from 'assets';
import { StyledLinkExt } from 'components/atoms';
import { NavMenu } from 'components/molecules';

const Navbar = () => {
  return (
    <div className="fixed top-0 w-full">
      <nav className="flex justify-between max-w-6xl mx-auto px-4 xl:px-0 py-3 items-center">
        <StyledLinkExt to="https://www.google.com/">
          <div className="h-10">
            <img className="w-full h-full" src={IconLogo} alt="icon-logo" />
          </div>
        </StyledLinkExt>
        <NavMenu />
        {/* NavMenuMobile dan NavControl */}
      </nav>
    </div>
  );
};

export default Navbar;
