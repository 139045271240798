import React from 'react';

const Doctor = () => {
  return (
    <div className="h-screen">
      <div className="flex justify-center items-center h-full">
        <h1 className="text-5xl font-semibold text-brand">Find a Doctor</h1>
      </div>
    </div>
  );
};

export default Doctor;
