import React from 'react';
import { IconLogoLight } from 'assets';
import { TextDescBase, TextTitleBase } from 'components/atoms';

const Footer = () => {
  return (
    <footer className="w-full py-10 md:py-16 bg-gradient-to-tr from-brand-dark to-brand-light">
      <div className="max-w-6xl px-4 xl:px-0 mx-auto flex flex-col space-y-10 md:space-y-0 lg:flex-row lg:space-x-40">
        <div className="flex items-start flex-col max-w-sm space-y-2 lg:space-y-6">
          <div className="h-8 md:h-10">
            <img
              className="h-full w-full"
              src={IconLogoLight}
              alt="icon-logo-light"
            />
          </div>
          <TextDescBase
            className="font-light text-sm md:text-base text-white"
            desc="Trafalgar provides progressive, and affordable healthcare, accessible on mobile and online for everyone"
          />
          <TextDescBase
            className="font-light text-sm md:text-base text-white"
            desc="©Trafalgar PTY LTD 2020. All rights reserved"
          />
        </div>
        <div className="flex justify-between mb-10 lg:mb-0 lg:space-x-32">
          <div className="flex flex-col space-y-6">
            <TextTitleBase
              className="font-bold text-sm md:text-base text-white"
              title="Company"
            />
            <div className="flex flex-col space-y-4">
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="About"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Testimonials"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Find a doctor"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Apps"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-6">
            <TextTitleBase
              className="font-bold text-sm md:text-base text-white"
              title="Region"
            />
            <div className="flex flex-col space-y-4">
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Indonesia"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Singapore"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Hongkong"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Canada"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-6">
            <TextTitleBase
              className="font-bold text-sm md:text-base text-white"
              title="Help"
            />
            <div className="flex flex-col space-y-4">
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Help center"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Contact support"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="Instructions"
              />
              <TextDescBase
                className="font-light text-sm md:text-base text-white"
                desc="How it works"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
