import React from 'react';
import {
  AboutLink,
  AppLink,
  DoctorLink,
  HomeLink,
  TestimonialLink,
} from 'config';
import { StyledLink } from 'components/atoms';

const NavMenu = () => {
  return (
    <ul className="hidden md:flex items-center space-x-10">
      <li>
        <StyledLink
          to={HomeLink}
          className="text-base text-color-nav font-bold"
        >
          Home
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to={DoctorLink}
          className="text-base text-color-nav font-light"
        >
          Find a doctor
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to={AppLink}
          className="text-base text-color-nav font-light"
        >
          Apps
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to={TestimonialLink}
          className="text-base text-color-nav font-light"
        >
          Testimonials
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to={AboutLink}
          className="text-base text-color-nav font-light"
        >
          About us
        </StyledLink>
      </li>
    </ul>
  );
};

export default NavMenu;
