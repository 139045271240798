import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const StyledLink = (props) => {
  return (
    <Link className={props.className} to={props.to}>
      {props.children}
    </Link>
  );
};
export const StyledLinkExt = (props) => {
  return (
    <div>
      {props.blank ? (
        <a
          href={props.to}
          target="_blank"
          rel="noreferrer"
          className={props.className}
        >
          {props.children}
        </a>
      ) : (
        <a href={props.to} className={props.className}>
          {props.children}
        </a>
      )}
    </div>
  );
};

StyledLink.propTypes = {
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};
StyledLinkExt.propTypes = {
  className: PropTypes.string.isRequired,
  blank: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

StyledLinkExt.defaultProps = {
  blank: null,
};
