import React from 'react';
import PropTypes from 'prop-types';

export const TextTitleBase = (props) => {
  return (
    <div>
      {props.h1 ? (
        <h1 className={`${props.className}`}>
          {props.title}
          {props.children}
        </h1>
      ) : (
        <span className={`${props.className}`}>
          {props.title}
          {props.children}
        </span>
      )}
    </div>
  );
};
export const TextTitle = (props) => {
  return (
    <div>
      {props.h1 ? (
        <h1 className={`${props.className} font-bold text-color-title`}>
          {props.title}
          {props.children}
        </h1>
      ) : (
        <span className={`${props.className} font-bold text-color-title`}>
          {props.title}
          {props.children}
        </span>
      )}
    </div>
  );
};
export const TextDescBase = (props) => {
  return (
    <div>
      {props.p ? (
        <p className={`${props.className}`}>
          {props.desc}
          {props.children}
        </p>
      ) : (
        <span className={`${props.className}`}>
          {props.desc}
          {props.children}
        </span>
      )}
    </div>
  );
};
export const TextDesc = (props) => {
  return (
    <div>
      {props.p ? (
        <p className={`${props.className} font-light text-color-desc`}>
          {props.desc}
          {props.children}
        </p>
      ) : (
        <span className={`${props.className} font-light text-color-desc`}>
          {props.desc}
          {props.children}
        </span>
      )}
    </div>
  );
};

TextTitleBase.propTypes = {
  h1: PropTypes.string,
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string,
};

TextTitleBase.defaultProps = {
  h1: null,
  children: null,
};

TextTitle.propTypes = {
  h1: PropTypes.string,
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string,
};

TextTitle.defaultProps = {
  h1: null,
  children: null,
};

TextDescBase.propTypes = {
  p: PropTypes.string,
  className: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  children: PropTypes.string,
};

TextDescBase.defaultProps = {
  p: null,
  children: null,
};

TextDesc.propTypes = {
  p: PropTypes.string,
  className: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  children: PropTypes.string,
};

TextDesc.defaultProps = {
  p: null,
  children: null,
};
