import React from 'react';
import { Navbar } from 'components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { About, Apps, Doctor, Home, Testimonial } from 'pages';

const App = () => {
  return (
    <Router>
      <div className="bg-white">
        <Navbar />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/app" element={<Apps />} />
          <Route path="/doctor" element={<Doctor />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
