import { StyledLinkExt } from 'components';
import React from 'react';

const Apps = () => {
  return (
    <div className="h-screen">
      <div className="flex justify-center items-center h-full">
        <StyledLinkExt>
          <h1 className="text-5xl font-semibold text-brand">Apps</h1>
        </StyledLinkExt>
      </div>
    </div>
  );
};

export default Apps;
