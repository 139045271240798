import React from 'react';
import { Helmet } from 'react-helmet';
import {
  HomeHero,
  HomeOurService,
  HomeHealthCare,
  CustomerSaying,
  HomeMobileApp,
  Footer,
  Article,
} from 'components';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Standardization - Litedex</title>
        <meta name="description" content="Litedex Standardization" />
        <meta name="og:description" content="Litedex Standardization" />
      </Helmet>
      <main>
        <div className="max-w-6xl px-4 xl:px-0 mx-auto mt-16 md:mt-0">
          {/* Hero Section */}
          <section>
            <HomeHero />
          </section>
          {/* Our Services Section */}
          <section>
            <HomeOurService />
          </section>
          {/* Leading healthcare providers Section */}
          <section>
            <HomeHealthCare />
          </section>
          {/* Download our mobile apps Section */}
          <section>
            <HomeMobileApp />
          </section>
          {/* What our customer are saying Section */}
          <section>
            <CustomerSaying />
          </section>
          {/* Article Section */}
          <section>
            <Article />
          </section>
        </div>
        <Footer />
      </main>
    </>
  );
};
export default Home;
