import PropTypes from 'prop-types';
import React from 'react';

const CardOurService = (props) => {
  return (
    <div className="px-5 py-6 lg:px-8 lg:py-10 flex flex-col justify-start space-y-2 md:space-y-6 max-w-xs shadow-card-comp rounded-3xl">
      <div className="h-14 md:h-20">
        <img className="h-full" src={props.src} alt={props.alt} />
      </div>
      {props.children[0]}
      {props.children[1]}
    </div>
  );
};

export default CardOurService;

CardOurService.propTypes = {
  children: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

CardOurService.defaultProps = {
  children: null,
};
