// import IconArrowRight from 'assets';
import { StyledLinkExt, TextDesc } from 'components/atoms';
import React from 'react';
import PropTypes from 'prop-types';
import IconArrowRight from 'assets/icons/other';

const CardArticle = (props) => {
  return (
    <div className="flex flex-col max-w-xs shadow-card-comp rounded-3xl overflow-hidden">
      <div className="h-40 md:h-52">
        <img
          className="object-cover h-full w-full"
          src={props.src}
          alt="img-article"
        />
      </div>
      <div className="p-4 md:p-6 flex flex-col space-y-4 items-start">
        <TextDesc className="text-base md:text-lg" title={props.title} />
        <TextDesc className="text-sm md:text-base" desc={props.desc} />
        <StyledLinkExt to={props.to} className="flex items-end space-x-2">
          <span className="text-brand">Read More</span>
          <img
            className="w-5 h-5"
            src={IconArrowRight}
            alt="icon-arrow-right"
          />
        </StyledLinkExt>
      </div>
    </div>
  );
};

export default CardArticle;

CardArticle.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
