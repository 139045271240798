import React from 'react';
import PropTypes from 'prop-types';

const Line = (props) => {
  return <div className={`${props.className} border-b-4 w-28 rounded-3xl`} />;
};

Line.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Line;
